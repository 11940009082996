import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { SassyText, Section, SectionWrapper, Title } from "../core/commonExports"
import { OnDesktop, OnMobile } from "../DesktopMobileComps"

const HeroSection = ({content, subcontent, pageName}) => {
    let img = null, title = null

    switch (pageName) {
        case "shop":
            img = (
                <HeroImgContainer width="min(590px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/shop.webp"
                        alt="shop"
                        width={466}
                        height={335}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your shop</SassyText> need protection?</>)
            break
        case "restaurant":
            img = (
                <HeroImgContainer width="min(590px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/restaurant.webp"
                        alt="restaurant"
                        width={466}
                        height={335}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your restaurant</SassyText> need protection?</>)
            break
        case "factory":
            img = (
                <HeroImgContainer width="min(590px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/factory.webp"
                        alt="factory"
                        width={466}
                        height={335}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your factory</SassyText> need protection?</>)
            break
        case "storage":
            img = (
                <HeroImgContainer width="min(590px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/storage.webp"
                        alt="storage"
                        width={500}
                        height={335}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your storage business</SassyText> need protection?</>)
            break
        case "service":
            img = (
                <HeroImgContainer width="min(485px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/office.webp"
                        alt="office"
                        width={485}
                        height={284}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your service business</SassyText> need protection?</>)
            break
        default: 
            img = (
                <HeroImgContainer width="min(485px, 100%)">
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/factory.webp"
                        alt="factory"
                        width={485}
                        height={284}
                        placeholder="blur"
                    />
                </HeroImgContainer>
            )
            title = (<>Why does <SassyText>your business</SassyText> need protection?</>)
    }

    return (
        <Section background="#fff">
            <SectionWrapper>
                <HeroSectionContainer>
                    <OnMobile>
                        <Title fontSize="38px" lineHeight="68px" mfontSize="26px" mlineHeight="46px" style={{ marginTop: "65px" }}>
                            {title}
                        </Title>
                    </OnMobile>
                    {img}
                    <OnDesktop style={{width: "auto", padding: "1rem 0"}}>
                        <Title fontSize="38px" lineHeight="68px" mfontSize="26px" mlineHeight="46px">
                            {title}
                        </Title>
                        <ContentContainer>
                            {content}
                        </ContentContainer>
                    </OnDesktop>
                    <OnMobile>
                        <ContentContainer>
                            {content}
                        </ContentContainer>
                    </OnMobile>
                </HeroSectionContainer>
            </SectionWrapper>
        </Section>
    )
}

const HeroImgContainer = styled.div`
    width: ${p => p.width};
    min-width: 350px;
    margin-right: 1.5rem;

    @media (max-width: 768px) {
        width: min(250px, 100%);
        margin-right: 0;
    }
`

const HeroSectionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 64px 0;
    margin-bottom: 0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        margin-bottom: 0;
    }
`

const ContentContainer = styled.div`
    margin: 46px auto;
    margin-bottom: 0;
    width: auto;
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: min(420px, 100%);
        font-size: 16px;
        line-height: 22px;
    }
`

export default HeroSection