import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { SassyText, Section, SectionWrapper, SubTitle, Title } from "../core/commonExports"
import { OnDesktop, OnMobile } from "../DesktopMobileComps"

const AboutBSUS = () => {
    const content = (
        <ContentContainer>
            Bharat Sookshma Udyam Suraksha (BSUS) is a
            <strong > Fire and Allied Perils Insurance product </strong>
            available in India that protects SMEs against loss due to fire,
            explosion, natural calamities, malicious activity, and much more.
        </ContentContainer>
    )
    const subcontent = (
        <ContentContainer style={{marginTop: "1.5rem"}}>
            You can opt for a BSUS policy if your business’s total insurable
            assets at {" "}
            <SassyText>
                any one location are worth less than or equal to ₹5 Crore
            </SassyText>
        </ContentContainer>
    )

    return (
        <Section background="#fff">
          <SectionWrapper>
            <Container>
                <OnMobile>
                    <SubTitle fontSize="36px" lineHeight="43.2px" mfontSize="26px" mlineHeight="32px">
                        <SassyText>Bharat Sookshma Udyam Suraksha</SassyText> (BSUS)
                    </SubTitle>
                </OnMobile>
                <OnDesktop style={{width: "auto", padding: "1rem 0"}}>
                    <Title fontSize="36px" lineHeight="43.2px" mfontSize="26px" mlineHeight="32px">
                        <SassyText>Bharat Sookshma Udyam Suraksha</SassyText> (BSUS)
                    </Title>
                    {content}
                    <br />
                    {subcontent}
                </OnDesktop>
                <OnMobile>
                    {content}
                </OnMobile>
                <ImgContainer>
                    <StaticImage 
                        src="../../assets/images/type-of-business-pages/shop-graphic.webp"
                        alt="shop"
                        width={432}
                        height={335}
                        placeholder="blur"
                    />
                </ImgContainer>
                <OnMobile>
                    {subcontent}
                </OnMobile>
            </Container>
        </SectionWrapper>
      </Section>
    )
}

export default AboutBSUS

const ImgContainer = styled.div`
    width: min(432px, 100%);
    min-width: 350px;

    @media (max-width: 768px) {
        width: min(250px, 100%);
    }
`

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 64px 0;
    margin-bottom: 0;
    gap: 2.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 40px 0;
        margin-bottom: 0;
        gap: 0;
    }
`

const ContentContainer = styled.div`
    margin: 46px auto;
    margin-bottom: 0;
    width: auto;
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: min(420px, 100%);
        font-size: 16px;
        line-height: 22px;
    }
`
